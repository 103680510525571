<template>
  <div v-if="Object.keys(dataDashboard).length > 0">
    <div class="d-flex mb-1">
      <b-form-input v-model="referenceLink" class="d-inline-block mr-1" disabled />
      <b-button variant="primary" class="w-25 d-none d-lg-flex justify-content-center" @click="copyReferenceLink">
        {{ $t("Copiar link de convite") }}
      </b-button>
      <b-button variant="primary" class="w-25 d-flex d-lg-none justify-content-center" @click="copyReferenceLink">
        {{ $t("Copiar") }}
      </b-button>
    </div>
    <h4>{{ $t('Bônus das indicações diretas') }}</h4>
    <hr>
    <b-row>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataDirectReferences.totalBonusDirect)
        " :statistic-title="$i18n.t('Recebido dos afiliados diretos')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="UsersIcon" color="warning"
          :statistic="dataDashboard.dataDirectReferences.totalReferences"
          :statistic-title="$i18n.t('Total de afiliados diretos')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="UserCheckIcon" color="success"
          :statistic="dataDashboard.dataDirectReferences.activeReferences"
          :statistic-title="$i18n.t('Afiliados diretos ativos')" />
      </b-col>
    </b-row>
    <h4>{{ $t('Bônus de rede do 2º ao 6º nível') }}</h4>
    <hr>
    <b-row>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataUnilevelReferences.totalBonusLevel2)
        " :statistic-title="$i18n.t('Recebido das indicações de 2º nível')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataUnilevelReferences.totalBonusLevel3)
        " :statistic-title="$i18n.t('Recebido das indicações de 3º nível')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataUnilevelReferences.totalBonusLevel4)
        " :statistic-title="$i18n.t('Recebido das indicações de 4º nível')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataUnilevelReferences.totalBonusLevel5)
        " :statistic-title="$i18n.t('Recebido das indicações de 5º nível')" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="DollarSignIcon" :statistic="
          moneyFormat(dataDashboard.dataUnilevelReferences.totalBonusLevel6)
        " :statistic-title="$i18n.t('Recebido das indicações de 6º nível')" />
      </b-col>
    </b-row>
    <h4>{{ $t('Bônus da árvore binária') }}</h4>
    <hr>
    <b-row>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="SmileIcon" color="success" :statistic="
          moneyFormat(dataDashboard.dataBinaryTree.pointsEarned)
        " :statistic-title="$i18n.t('Valor recebido de bônus binário')" />
      </b-col>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="CornerDownLeftIcon" color="info"
          :statistic="parseInt(dataDashboard.dataBinaryTree.pointsChildLeft)"
          :statistic-title="$i18n.t('Pontuação do lado esquerdo')" />
      </b-col>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="CornerDownRightIcon" color="primary"
          :statistic="parseInt(dataDashboard.dataBinaryTree.pointsChildRight)"
          :statistic-title="$i18n.t('Pontuação do lado direito')" />
      </b-col>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="FrownIcon" color="danger" :statistic="
          moneyFormat(dataDashboard.dataBinaryTree.pointsLost)
        " :statistic-title="$i18n.t('Valor perdido de bônus binário')" />
      </b-col>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="CornerDownLeftIcon" color="info"
          :statistic="dataDashboard.dataBinaryTree.lenghtBinaryLeft"
          :statistic-title="$i18n.t('Total de ativos do lado esquerdo')" />
      </b-col>
      <b-col md="4" sm="6" >
        <statistic-card-horizontal icon="CornerDownRightIcon" color="primary"
          :statistic="dataDashboard.dataBinaryTree.lenghtBinaryRight"
          :statistic-title="$i18n.t('Total de ativos do lado direito')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import copy from "copy-text-to-clipboard";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardWithLineChart,
    referenceLink: "",
  },
  data() {
    return {
      dataDashboard: {},
      siteTraffic: {
        series: [
          {
            name: "Binary Bonus",
            data: [150, 200, 125, 225, 200, 250],
          },
        ],
      },
      activeUsers: {
        series: [
          {
            name: "Active Users",
            data: [750, 1000, 900, 1250, 1000, 1200, 1100],
          },
        ],
      },
      newsletter: {
        series: [
          {
            name: "Newsletter",
            data: [365, 390, 365, 400, 375, 400],
          },
        ],
      },
      referenceLink: "",
    };
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
  },
  mounted() {
    this.referenceLink =
      process.env.VUE_APP_FRONT_URL + "/ref/" + this.returnUser._id;

    this.$store.dispatch("affiliatesDashboard").then((resp) => {
      this.dataDashboard = resp;
    });
  },
  methods: {
    copyReferenceLink() {
      let copyText = this.referenceLink;
      copy(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CoffeeIcon",
          variant: "info",
          title: copyText,
          text: this.$i18n.t(
            "esse link foi copiado para a sua área de transferências"
          ),
        },
      });
    },
  },
};
</script>